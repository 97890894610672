import EF1 from './received_1396343447503492.jpeg';
import EF2 from './received_3741647478596112.jpg';
import EF3 from './FB_IMG_1645310678097.jpg';


  
  export default function Example() {
    return (
      <div className="overflow-hidden  bg-white-100">
        <div className="pl-12 mx-auto max-w-7xl sm:px-6 lg:px-16">
          <div className="max-w-2xl py-16 mx-auto sm:py-24 lg:py-32 lg:max-w-none">
            <h2 className="font-serif text-3xl font-extrabold text-gray-900 ">Our Team</h2>
            <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-24">
                  <div className="relative object-cover object-center w-5/6 overflow-hidden bg-white lg:w-5/6 lg:h-full h-1/6 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                 <div>
                <img 
                src={EF2}
                className="object-cover object-center lg:w-full lg:h-5/6"
                layout="fill"
                alt="alt"
                />
                            <h1 className="pt-10 font-serif text-lg ">
              <strong>Breanna Butts</strong>
                <br></br>
                <br></br>
                Our Secretary is no stranger to hard work or charity. From making sandwiches and care packages for the less fortunate in church basements every Friday, to soup Kitchens in Souls Harbour. She’s always willing to help! Recent Grad of Cape Breton Business College ready to take on the big task ahead!
            </h1>
                </div>
                
             </div>
                <div className="relative object-cover object-center w-5/6 overflow-hidden bg-white lg:w-5/6 lg:h-full sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                <div>
                <img 
                src={EF1}
                className="object-cover object-center lg:w-full lg:h-5/6"
                layout="fill"
                alt="alt"
                />
                            <h2 className="pt-10 font-serif text-lg">
              <strong>Treasurer, Zack Linden</strong>
              <br></br>
              <br></br>
              Born in Sydney raised in the Pier.  Zack has been an active member in his community since he was a wee lad, involved with community martial arts and local Bible Camps growing up. 
He went from camper to worker, always trying to teach people new things whether its jamming guitar, wilderness survival or shredding it on the Hill, he’s always willing to help mentor someone trying to learn!
He’s currently taking care of accounts, and we feel he is the perfect fit to look after ours!


            </h2>
                </div>
                
            </div>

 
            </div>
            
          </div>
          
        </div>
      </div>
     
    )
  }