// import EF1 from './beach-clean-up-tips-ideas-facebook-1200x630.png';
// import EF2 from './wldpsa1_000003422724_2400.jpg';
import EF3 from './FB_IMG_1645310678097.jpg';
import EF4 from './180186183_111936717706656_1948558735950278584_n.jpg';


  
  export default function Example() {
    return (
      <div className="overflow-hidden bg-gray-100 ">
        <div className="px-4 pl-12 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div id="support" className="max-w-2xl py-16 mx-auto sm:py-24 lg:py-32 lg:max-w-none">
            <h2 className="font-serif text-3xl font-extrabold text-gray-900">Our Support</h2>
            <div className="mt-6 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-6">
                  <div className="relative object-cover object-center w-5/6 overflow-hidden bg-gray-100 lg:w-full lg:h-full h-1/6 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                 <div>
                <img 
                src={EF3}
                className="object-cover object-center lg:w-full lg:h-full"
                layout="fill"
                alt="alt"
                />
                 
                </div>
                
             </div>
                <div className="relative object-cover object-center w-5/6 overflow-hidden text-xl font-medium bg-gray-100 lg:w-full lg:h-full h-1/6 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                <div>
                <h2 className="pt-4 font-serif">
                 <strong> Four Kings Fisheries Ltd- </strong>
                  <br></br>
                  <br></br>
                  A privately owned fishing company, that participates in the 2 month lobster season in Cape Breton. 
                  Kevin Gale is first mate on the company's boat.  Knowing him and his work ethic they are confident to put their name behind his project.
                  </h2>
                </div>
            </div>
            <div className="relative object-cover object-center w-5/6 overflow-hidden bg-gray-100 lg:w-full lg:h-full h-1/6 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 lg:aspect-h-1">
                 <div className="pt-12 ">
                <img 
                src={EF4}
                className="object-cover object-center lg:w-full lg:h-full"
                layout="fill"
                alt="alt"
                />
                 
                </div>
                
             </div>
                <div className="relative object-cover object-center w-5/6 overflow-hidden text-xl font-medium bg-gray-100 -lg lg:pt-6 lg:w-full lg:h-full h-1/6 sm:aspect-w-2 sm:aspect-h-1 sm:h-64 lg:aspect-w-1 ">
                <div>
                <h2 className="pt-12 font-serif">
                 <strong> Woodland Home building center  </strong>
                  <br></br>
                  <br></br>
                  Serving Glace Bay and surrounding communities with homes, materials and expertise since 2015.
                  <br></br>
                  <br></br>
                  "At Home Building Centre you will find a range of products carefully selected for home renovation, repair and maintenance projects. Lumber, paint, hand and power tools, plumbing and electrical supplies and all manner of building materials - including plans - are available here."
                  </h2>
                </div>
            </div>

            </div>
            
          </div>
          
        </div>
      </div>
     
    )
  }

