import RM from './pngwing.com (2).png';

export default function Example() {
  return (

    <div id="roadmap" className="py-12 overflow-hidden bg-custombg">
      <div className="px-4 font-serif sm:px-6 lg:ml-20 lg:px-16">
        <div className="lg:text-left">

            <p className="mt-2 text-3xl leading-8 tracking-tight text-white font-strong sm:text-4xl">
            <strong>Our Mission</strong>
          </p>
          <br></br>
          <div  className="flex items-center justify-between">
          <h1 className="max-w-4xl mt-4 mb-10 text-xl font-medium text-white lg:text-left">
          Our Mission is to simply assist in the work of  making the ocean a cleaner, and safer place for the wildlife that inhabit it. So future generations will have the benefit of enjoying all the amazing activities our oceans have to offer.
        <br></br>
        <br></br>

        Labour is a more beneficent ministration than man’s ignorance comprehends, or his complaining’s will admit. Even when its end is hidden from him, it is not mere blind drudgery. It is all a training, a discipline, a development of energies, a nurse of virtues, a school of improvement. From the poor boy who gathers sticks for his mother’s hearth, to the strong men who fells the oak or guides the ships, every human toiler, with every weary step and urgent task, is obeying a wisdom far above his own wisdom, and fulfilling a design far beyond his own design. <br></br><br></br>~Albert Pike
          </h1>
      
          
        </div>
        </div>

      </div>
    </div>
  )
}